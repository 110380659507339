@import '../../css/variabilities.scss';

.clients {



    h2 {
        font: 2.3rem $roboto-slab;
        text-transform: uppercase;
        color: #222222;
        font-weight: 700;
        margin-bottom: 5rem;
        margin-top: 2rem;
     }
}

.clientsWrapper {
    display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        grid-gap:1rem;


     @media (max-width: 1024px) {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
     }
     @media (max-width: 400px) {
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
     }

    .clientName {
   
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
            padding-top: 0;
            padding-bottom: 1rem;
        }
    }

    img {
        max-width: 100%;

        @media (max-width: 750px) {
            margin-top: 7%;
            max-height: 80%;
        }
        @media (max-width: 600px) {
             margin-top: 17%;
             max-height: 60%;
         }
        @media (max-width: 450px) {
            margin-top: 30%;
            max-height: 50%;
        }
        @media (max-width: 360px) {
            margin-top: 40%;
            max-height: 45%;
        }


    }
    
       
     img[alt='logo']{
         max-width: 80%;

         @media (max-width: 750px) {
            margin-top: 0;
            max-height: 100%;
        }
        @media (max-width: 600px) {
            margin-top: 0;
            max-height: 100%;
         }
        @media (max-width: 450px) {
            margin-top: 0;
            max-height: 100%;
        }
        @media (max-width: 360px) {
            margin-top: 0;
            max-width: 70%;
            max-height: 100%;
        }

     }

    .clientLogo {
        height: 7rem;
        img {
            object-fit: contain;
            height: 100%;
        }
    }

    svg  {
        width: 5rem;
        height: 5rem;
    }


     p {
        font: 0.7rem $open-sans;
        text-transform: uppercase;
        color: #646464;
        padding-top: 1rem;
     }

     .ri-trigger {
         width: 300px;
         border: 2px solid rgb(171, 171, 171);
         box-shadow: 3px 2px 7px 0px rgba(72, 72, 72, 0.2);
     }

}