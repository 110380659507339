@import './css/variabilities.scss';
.App {
  text-align: center;


  .titlePanel {


    .logoButtons {
 
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        @media (max-width: 1048px) {
          justify-content: space-around;
      };
        @media (max-width: 750px) {
          flex-direction: column;
          gap: 0.5rem;
      };
    

      a {
        text-decoration: none;
        border: 1px solid rgb(213, 53, 85);
        border-radius: 5px; 
        color: rgb(252, 246, 246);
        background-color: rgb(213, 53, 85);
        padding: 1rem;
        text-transform: uppercase;
        margin: 0;
        font-family: $roboto-slab;

        @media (max-width: 350px) {
          font-size: 0.8rem;
          padding: 0.7rem;
          margin-top: 1rem;
      };

    };

    a + a {
        margin-left: 1rem;
    }; 
    };    

    img {
        max-width: 20rem;

        @media (max-width: 450px) {
          max-width: 22rem;
          //margin-top: 1.5rem;
      };
    };

   

    .mainButtons {
        display: flex;
        justify-content: space-between;

    };

};

  .navMenu {
    box-shadow: 0px 1px 3px 0 rgba(155, 155, 155, 0.2);
    background-color:#fbfbfb;

    @media (max-width: 750px) {
      display: none;
  }
  

    nav {
      display: flex;
      justify-content: space-around;
    }; 
  };

  .nav-link {
    box-sizing: border-box;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    font-family: $roboto-slab;
    font-weight: 700;
    color: #393939;
    padding: 3rem 0;

    @media (max-width: 1024px) {
      padding: 1rem 0;
  }

    &.active {
      border-bottom: 5px solid #43a906;
      color: #43a906;
    };
    &:hover, &:focus  {
      color: #43a906;
    };
    
  };



  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: 600px) {
      flex-direction: column;
  }

  }
  .action1 {
    height: 300px;
    object-fit: cover;
    @media (max-width: 900px) {
      object-fit: contain;
      height: 200px;
  }
  }
  .action2 {
    height: 300px;
    object-fit: cover;
    @media (max-width: 900px) {
      object-fit: contain;
      height: 200px;
  }
  }
}


