@import "../../../../css/variabilities.scss";

.wicket {
    color: #393939;
    p {
        text-align: justify;
    }

    h2 {
        font-family: $roboto-slab;
        size: 3rem;
        

    }



    h4 {
        text-transform: uppercase;
        size: 1.25rem;
        font-family: $roboto-slab;
    }



    .menu_content {
        display: flex;

        .button_content {
            flex-grow: 1;
        }
    }


    .content {

        img[alt='калитка Дорхан']{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        

        .plusWrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            grid-template-rows: 1fr;
            gap: 3%;
            justify-items: center;
            align-items: center;
            margin-bottom: 7rem;

            @media (max-width: 535px) {
                gap: 0%;
            }

            img {
                object-fit: cover;
                width: 20rem;
                max-height: 100%;
            }

            p {
                margin-top: 1.5rem;
            }
        }

        .variantWrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            grid-template-rows: 1fr;
            gap: 2%;
            justify-items: center;
            margin: 6rem 0;

            @media (max-width: 545px) {
                gap: 0%;
                margin-bottom: 5rem;
                margin-top: 0;
            }

            
                img {
                    object-fit: cover;
                    width: 10rem;
                    height: 20rem;
                }
        }
       
        .additionWrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            grid-template-rows: 1fr;
            grid-gap: 1%;
           
    
            div {
                border: 1px solid rgb(184, 184, 184);
                box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.2);
                background-color: #fff;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 22rem;
            }
    
            p {
                padding: 1rem;
            }
        }
    }
    .characteristics {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        justify-items: center;
        margin-bottom: 7rem;
        
   
        p {
           padding: 1rem;
           border-bottom: 1.5px solid rgb(194, 187, 187);
        }
    }

    .design, .surface, .shield, .premium, .color {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-template-rows: 1fr;
        gap: 1rem;
        justify-items: center;
        margin-bottom: 7rem;
    
        @media (max-width: 700px) {
            grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
         }
    
        div {
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            width: 20rem;
    
            @media (max-width: 1100px) {
               width: 17rem;
            }
            @media (max-width: 800px) {
                width: 15rem;
             }
             @media (max-width: 700px) {
                width: 25rem;
             }
        }
    
        img {
            width: 100%;
            object-fit: fill;
        }
     }
    
     .design {
         img {
             width: 15rem;
         }
     }
    
     .shield {
    
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        gap: 2rem;
    
        div: {
            width: 25rem;
        };
    
        img {
            height: 22rem;
            width: 10rem;
            object-fit: cover;
        }
     }
    .baseWrapper {
        box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.4);
        padding: 0.3rem;
        margin-top: 3rem;
     }
    
     .base  {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center;
        margin: 1rem;
    
    
        img {
            object-fit: cover;
            width: 20rem;
        }
    }

    .additionWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        grid-gap: 3rem;
        margin-bottom: 10rem;
    
        img {
            width: 100%;
        }
    
        div {
            border: 1px solid rgb(184, 184, 184);
            box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.2);
            background-color: #fff;
        }
    
        p {
            padding: 1rem;
        }
    };
}
