@import '../../css/variabilities.scss';

.measuring {

    padding-top: 7rem;
    padding-bottom: 7rem;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    @media (max-width: 1024px) {
        padding-top: 3rem;
        padding-bottom: 3rem; 
    }

    @media (max-width: 375px) {
        width: 100%;
    }
    
    
    h2 {
            font-family: $roboto-slab;
            font-size: 2rem;
            color: #222222;
            text-transform: uppercase;
            font-weight: bold;
            padding-bottom: 0.5rem;

            @media (max-width: 1024px) {
                font-size: 1.5rem;
            }
    };
    button {
        padding: 1.5rem 5.2rem;
        margin-top: 2.5rem;
        border-radius: 2px;
        background-color: #43a906;
        font-family: $roboto-slab;
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
        cursor: pointer;

        @media (max-width: 1024px) {
            margin-top: 1rem;
        }
        @media (max-width: 375px) {
            padding: 1.5rem 4rem;
        }
    }
    [placeholder] {
        font-family: $open-sans;
        font-size: 1rem;
        font-style: italic;
        text-align: left;
        padding-left: 0.5rem;

    }

    .measuringWrapper {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0 auto;

        @media (max-width: 600px) {
            width: 80%;  
        };

        input {
            margin-bottom: 1rem;
            height: 2.5rem;
        };

        [placeholder="Комментарий"] {
            height: 10rem;
        }

        p {
            margin-bottom: 1rem;
            text-align: left;
        }

    }
}