@import '../../../css/variabilities.scss';


.aluFasade {




    p {
        text-align: left;
        margin-top: 1rem;

        @media (max-width: 375px) {
            text-align: justify;
        }
    }

    .menu_content{
        display: flex;

        .button_content {
            padding-top: 5rem;
        }
    
    }

    img[alt='алюминиевые фасады'] {
        width: 100%;
        object-fit: cover;
        height: 20rem;
        border: 1px solid rgb(150, 149, 149);
    }

    .coldWarmAl, .fasadeType {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center;
        grid-gap: 3%;
        margin: 3rem 0 8rem 0;

        img {
            object-fit: cover;
            width: 100%;
            height: 15rem;

            @media (max-width: 500px) {
                object-fit: contain;
            }
        }

        .kind {
            padding: 1rem;
            @media (min-width: 768px) and (max-width: 820px) {
                width: 25rem;
             }
        }
        p, li {
            text-align: justify;
        } 
    }

    .fasadeType {

        @media (min-width: 600px) and (max-width: 943px) {
            grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
         }

        .kind {
            @media (min-width: 600px) and (max-width: 943px) {
                width: 16rem;
                grid-gap: 1%;
             }
        }

    }

    .carousel{
        max-width: 600px;
        margin: 0 auto;

        @media (max-width: 650px) {
            max-width: 500px;  
        };
        @media (max-width: 530px) {
            max-width: 400px;  
        };
        @media (max-width: 430px) {
            max-width: 300px;  
        };

        .carousel_item {
            height: 30rem;
            max-width: 94%;
            object-fit: cover;
            border: 1px solid rgb(184, 184, 184);
            box-shadow: 5px 5px 5px rgba(72, 72, 72, 0.4);
            @media (max-width: 530px) {
                max-height: 25rem; 
            };
            @media (max-width: 430px) {
                height: 20rem; 
            };  
        }

        .alice-carousel__prev-btn{
            text-align: center;
        }
    }
}