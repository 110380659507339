@import '../../css/variabilities.scss';

.manufactures {

    padding: 2rem;
    //width: 60%;

    @media (max-width: 375px) {
        padding: 0;
    }

    
    
    img {
        width: 15rem;
    }

    h3 {
        text-transform: uppercase;
        font-family: $roboto-slab;
        font-weight: bold;
    }

    p {
        text-align: justify;
        padding: 1rem 2rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        
        align-items: center;

    }

    .container > div {
        border: 1px solid rgb(184, 184, 184);
        box-shadow: 0px 2px 7px 0 rgba(72, 72, 72, 0.2);
        background-color: #fff;
        padding: 2rem;
        margin-top: 2rem;
        width: 70%;

        @media (max-width: 600px) {
            width: 100%;
        }
    }
    
}