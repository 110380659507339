@import '../../css/variabilities.scss';

.footer {
    background-color: #222222;
    margin-top: 10rem;

      @media (max-width: 1024px) {
        margin-top: 3rem;
      }

    
    p, h3, a {
        font-family: $open-sans;
        color: #ffffff;
        font-size: 0.80rem;
        padding: 0.3rem 0;

        @media (max-width: 1024px) {
            font-size: 1.1rem;
        }
        @media (max-width: 768px) {
            font-size: 1.2rem;
            padding: 0.9rem 0; 
        };
        @media (max-width: 500px) {
             font-size: 1rem;
            padding: 0.9rem 0; 
         };
         @media (max-width: 450px) {
            font-size: 0.95rem;
           padding: 0.9rem 0; 
        };
        @media (max-width: 430px) {
            font-size: 1.1rem;
           padding: 0.9rem 0; 
        };
    };

    h3 {
        text-transform: uppercase;
        padding-bottom: 5rem;
    }


    .footerWrapper {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        grid-template-rows: 20% 80%;
        grid-auto-flow: column;

        @media (max-width: 430px) {
           grid-template-columns: 1fr 1fr;
           grid-template-rows: 1fr;
         };

         .footerProducts {
            grid-column: 2 / 4;

            @media (max-width: 430px) {
                    display: none;
                 };
         }

       
    }
    .mobileEmpty {
        @media (max-width: 430px) {
            display: none;
         };
    }

    .footerNavigation {

        @media (max-width: 430px) {
            font-size: 0.5rem;
            text-transform: uppercase;
         };
        
    }
.authors {
    font-size: 0.8rem;
    color: #ffffff;
    a {
        font-size: 0.8rem;
    }
}

}