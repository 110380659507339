
.image-container {
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.05);

    @media (max-width: 500px) {
        max-height: 50%; 
    };

    p {
        font-size: 1.3rem;
    }

    img {

        @media (max-width: 500px) {
            max-width: 100%;
        };
        
    }
  }