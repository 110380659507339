@import '../../../css/variabilities.scss';

.transparetnRollet {

    color: #393939;
    

    h3 {
        text-transform: uppercase;
        font-family: $roboto-slab;
        font-weight: bold;
        margin-top: 7rem;
    }

    h4 {
        text-transform: initial;
        font-family: $roboto-slab;
        font-size: 1.05rem;
    }

    p{
        text-align: justify;
        margin: 2rem 0;
    }

    .menu_content{
        display: flex;
    }
    .button_content {
        margin-left: 1.5rem;

        @media (max-width: 375px) {
            margin-left: 0;
        }

        a {
            border: 1px solid rgb(213, 53, 85);
            border-radius: 5px; 
            color: rgb(252, 246, 246);
            background-color: rgb(213, 53, 85);
            padding: 1em;
            text-transform: uppercase;
            margin-top: 5rem;
            font-family: $roboto-slab;
        };

        .plusesWrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
            grid-template-rows: 1fr;
            justify-items: center;
            border: 1px solid grey;
            
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border: 1px solid grey;   
            }

            div {
                background-color: #fbfbfb; 
            }
            
            p {
                padding: 1rem;
            }
        }
    }

    img[alt='прозрачные роллеты'] {
        object-fit: cover;
        width: 100%;
        height: 30rem;
        border: 1px solid grey;
    }
    .controlWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
        grid-template-rows: 1fr;
        gap: 1%;
        justify-items: center;
        align-items: center;
        background-color: #fbfbfb; 
        box-shadow: 0px 1px 3px 0 rgba(41, 41, 41, 0.2);
        padding: 2rem;

        img {
            object-fit: cover;
            width: 100%;
            max-height: 90%;
            margin-right: 1.5rem;
            border: 1px solid grey;
        }
    }
    
    .carousel{
        max-width: 600px;
        margin: 0 auto;

        @media (max-width: 650px) {
            max-width: 500px;  
        };
        @media (max-width: 530px) {
            max-width: 400px;  
        };
        @media (max-width: 430px) {
            max-width: 300px;  
        };

        img {
            height: 30rem;
            object-fit: contain;
            border: 1px solid rgb(184, 184, 184);
            box-shadow: 5px 5px 10px 0px rgba(72, 72, 72, 0.4);
            @media (max-width: 530px) {
                height: 25rem; 
            };
            @media (max-width: 430px) {
                height: 20rem;  
            };
    
        }
        .alice-carousel__prev-btn{
            text-align: center;
        }
    }
    .panoram {
        h3 {
            margin-top: 2rem;
            font-size: 16px;
        }
        a {
            margin-top: 0;
            margin-bottom: 5rem;
        }
    }
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    span {
        &::after {
            font-size: xx-large;
        }
    }
}
