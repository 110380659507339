@import '../../../css/variabilities.scss';


.aluWindow {


    p {
        text-align: left;
        margin-top: 1rem;

        @media (max-width: 375px) {
            text-align: justify;
        }
    }

    .menu_content{
        display: flex;

        .button_content {
            padding-top: 5rem;
            @media (max-width: 375px) {
                padding-top: 2rem;
            }
        }
    
    }

    .coldWarmAl, .windowForms, .AlBalconyVariants, .aluminWindowTitle{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center;
        grid-gap: 3%;
        margin: 3rem 0 8rem 0;

        img {
            object-fit: cover;
            width: 100%;
            height: 15rem;
        }

        .kind {
            padding: 1rem;
            @media (min-width: 768px) and (max-width: 820px) {
                width: 25rem;
             }
            }     
        

        p, li {
            text-align: justify;
        }
        
    }

    .windowForms, .AlBalconyVariants, .aluminWindowTitle {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));

        @media (min-width: 751px) and (max-width: 968px) {
            grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
         }
         @media (min-width: 500px) and (max-width: 750px) {
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
         }
         @media (max-width: 375px) {
             margin-bottom: 15rem;
         }

        img {
            object-fit: cover;
            width: 100%;
            height: 15rem;
        }
    }

    .aluminWindowTitle {
        box-shadow: 5px 5px 5px 5px rgba(235, 235, 235, 0.7);
        padding: 1rem;
        margin-bottom: 5rem;

        @media (max-width: 375px) {
            grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
            padding-right: 0;
            grid-gap: 0;
        }

        img {
            object-fit: unset;
        }
    }

    .carousel{
        max-width: 600px;
        margin: 0 auto;

        @media (max-width: 650px) {
            max-width: 500px;  
        };
        @media (max-width: 530px) {
            max-width: 400px;  
        };
        @media (max-width: 430px) {
            max-width: 300px;  
        };

        .carousel_item {
            height: 30rem;
            max-width: 94%;
            object-fit: cover;
            border: 1px solid rgb(184, 184, 184);
            box-shadow: 5px 5px 5px rgba(72, 72, 72, 0.4);
            @media (max-width: 530px) {
                height: 25rem; 
                object-fit: cover;
            };
            @media (max-width: 430px) {
                height: 20rem; 
            };  
        }

        .alice-carousel__prev-btn{
            text-align: center;
        }
    }
}