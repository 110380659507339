@import '../../../../css/variabilities.scss';

.doubleLeaf {
    color: #393939;

    img {
        margin-bottom: 2rem;
        border: 1px solid rgb(186, 186, 186);

        @media (max-width: 375px) {
            margin-bottom: 0;
        }
    }

    h3 {
        text-transform: uppercase;
        size: 3rem;
        font-family: $roboto-slab;
        font-weight: bold;
    }

    h4 {
        size: 2rem;
        text-transform: capitalize;
        text-decoration: underline;
        
    }

    .menu_content{
        display: flex;

        .button_content {
            flex-grow: 1;
        }
    
    }

    .content {

        img[alt='Распашные ворота'],  img[alt='распашные ворота Дорхан']{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        .plusWrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
            grid-template-rows: 1fr;
            gap: 1%;
            justify-items: center;
            align-items: center;
            margin-bottom: 5rem;

            

            div > img {
                object-fit: cover;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .doubleleafDescription {
            margin: 4rem;
            i {
                color: #43a906;
                font-size: 2rem;
                margin-right: 1rem;
            }
            p {
                text-align: justify;
            }
        }
        
        

        .variantWrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
            grid-template-rows: 1fr;
            gap: 1%;
            margin-bottom: 5rem;
            

            img {
                width: 23rem;
                margin-top: 0.5rem;
                border: 0;
            }
        }

        img[alt='цвета'] {
            width: 100%;
        }

        .controlWrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
            grid-template-rows: 1fr;
            gap: 1%;
            justify-items: center;
            align-items: center;
            @media (max-width: 375px) {
                grid-gap: 0;
            }


            div > img {
                object-fit: cover;
                width: 100%;
                max-height: 100%;
            }
        }
    }

    .characteristics {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        justify-items: center;
        
   
        p {
           padding: 1rem;
           border-bottom: 1.5px solid rgb(194, 187, 187);
        }
    }

    .design, .surface, .shield, .premium, .color {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-template-rows: 1fr;
        gap: 1rem;
        justify-items: center;
        margin-bottom: 7rem;
    
        @media (max-width: 500px) {
            grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
         }
    
        div {
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            width: 20rem;
    
            @media (max-width: 1100px) {
               width: 17rem;
            }
            @media (max-width: 800px) {
                width: 15rem;
             }
             @media (max-width: 600px) {
                width: 22rem;
             }
        }
    
        img {
            width: 100%;
            object-fit: fill;
        }
     }
    
     .design {
         img {
             width: 15rem;
         }
     }
    
     .shield {
    
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        gap: 2rem;
    
        div: {
            width: 25rem;
        };
    
        img {
            height: 14rem;
            width: 25rem;
            object-fit: cover;
        }
     }
    
 
   
}