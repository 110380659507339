@import '../../../css/variabilities.scss';


.aluDoor {

    h3 {
        margin-top: 10rem;
    }

    p {
        text-align: left;
        margin-top: 1rem;

        @media (max-width: 375px) {
            text-align: justify;
        }
    }

    .menu_content{
        display: flex;
    }

    .button_content {
        padding-top: 5rem;
        @media (max-width: 375px) {
            padding-top: 2rem;
        }
    }

    img[alt='алюминиевые двери'] {
        height: 20rem;
        object-fit: cover;
        width: 100%;
    }
    img[alt='варианты заполнения конструкций'] {
        margin-bottom: 10rem;

        @media (max-width: 375px) {
            width: 23rem;
            margin-bottom: 2rem;
        }
    }

    img {
        border: 1px solid rgb(218, 218, 218);
    }


    .coldWarmAl, .AlDoorFeatures, .AlDoorVariants {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        grid-gap: 3%;
        margin-top: 5rem;

        @media (max-width: 375px) {
            grid-gap: 0;
            margin: 0;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 15rem;
        }

        .kind {
            padding: 1rem;
            @media (min-width: 768px) and (max-width: 820px) {
                width: 25rem;
             }
            }     

        

        p, li {
            text-align: justify;
            padding: 1rem;
        }
        
    }

    .AlDoorFeatures {

        margin: 10rem 0 10rem 0;
        justify-items: center;
        align-items: center;
        box-shadow: 5px 5px 5px 5px rgba(235, 235, 235, 0.7);
        padding: 1rem;

        @media (max-width: 375px) {
            margin-top: 2rem;
            
        }
       
        img {
            object-fit: cover;
            width: 100%;
        }

    }
    .doorFilling {

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
        grid-template-rows: 1fr;
        justify-items: center;

        @media (min-width: 700px) and (max-width: 869px) {
            grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        }

        img {
            @media (min-width: 870px) and (max-width: 990px) {
                width: 28rem;
            }
            @media (min-width: 700px) and (max-width: 869px) {
                width: 25rem;
            }
            @media (min-width: 400px) and (max-width: 699px) {
                width: 23rem;
            }
        }
        


    }

    .AlDoorVariants {

        @media (min-width: 700px) and (max-width: 943px) {
            grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        }
        @media (max-width: 599px) {
            grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        }
        img {
            object-fit:cover;
            width: 100%;
            height: 20rem;
        }

        img[alt='маятниковая дверь'] {
            object-fit: unset;
        }
    }


    .carousel{
        max-width: 600px;
        margin: 0 auto;

        @media (max-width: 650px) {
            max-width: 500px;  
        };
        @media (max-width: 530px) {
            max-width: 400px;  
        };
        @media (max-width: 430px) {
            max-width: 300px;  
        };

        .carousel_item {
            height: 30rem;
            max-width: 94%;
            object-fit: cover;
            border: 1px solid rgb(184, 184, 184);
            box-shadow: 5px 5px 5px rgba(72, 72, 72, 0.4);
            @media (max-width: 530px) {
                height: 25rem; 
                object-fit: cover;
            };
            @media (max-width: 430px) {
                height: 20rem; 
            };  
        }

        .alice-carousel__prev-btn{
            text-align: center;
        }
    }

}