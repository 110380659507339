@import '../../css/variabilities.scss';

.gates {
    box-sizing: border-box;


    .menu-products {
        display: flex;
        text-align: center;
    };

    .products {
        flex-basis: 60%;
        margin: 10rem auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-gap: 3%;
        
        @media (max-width: 1024px) {
            margin: 5rem auto; 
        }
         @media (min-width: 768px) and (max-width: 841px) {
            grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
            flex-basis: 100%;
         }
          @media (max-width: 750px) {
             flex-basis: 100%;
             grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
             justify-items: center;
         }

         @media (max-width: 580px) {
            flex-basis: 100%;
            grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
            grid-gap: 2rem;
        }
         @media (max-width: 375px) {
            flex-basis: 100%;
            grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
            grid-gap: 1%;
            margin-bottom: 5rem;
            margin-top: 2rem;
        }
         @media (max-width: 320px) {
            flex-basis: 100%;
            grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
            grid-gap: 1%;
            margin-bottom: 5rem;
            margin-top: 2rem;
        }

        
       

        .product {
            border: 1px solid rgb(184, 184, 184);
            box-shadow: 3px 2px 7px 0px rgba(72, 72, 72, 0.2);
            background-color: #fff;
            &:hover {

                p {
                    color: #43a906;
                }
              };

            @media (max-width: 750px) {
                width: 24rem;
            }

            @media (max-width: 580px) {
                width: 27rem;
            }
        
            @media (max-width: 320px) {
                width: 20rem;
            }


            img {
                height: 15rem;
                width: 100%;
                object-fit: cover;
                @media (max-width: 375px) {
                    height: 10rem;
                }
                       
            }
        }

        p {
            font-family: $roboto-slab;
            text-transform: uppercase;
            padding: 1rem;

            @media (max-width: 375px) {
                padding: 0.5rem;
            }

        }
    }
}