@import '../../../css/variabilities.scss';


.aluBalcony {


    p {
        text-align: left;
        margin-top: 1rem;

        @media (max-width: 375px) {
            text-align: justify;
        }
    }

    .menu_content{
        display: flex;

        .button_content {
            padding-top: 5rem;

            @media (max-width: 375px) {
                padding-top: 2rem;
            }
        }
    
    }

    img[alt='алюминиевые лоджии'] {
        object-fit: cover;
        height: 25rem;
        width: 100%;
    }

    .coldWarmAl, .AlBalconyVariants, .alBalcony{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center;
        grid-gap: 3%;
        margin-top: 5rem;

        @media (max-width: 375px) {
            grid-gap: 1%;
            margin-top: 1rem;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 15rem;
        }

        .kind {
            padding: 1rem;
           @media (min-width: 768px) and (max-width: 820px) {
                 width: 25rem;
             }
            }     

        p, li {
            text-align: justify;
        }
        
    }

    .AlBalconyVariants {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));

        @media (min-width: 751px) and (max-width: 929px) {
            grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
         }
       
    }
    .alBalcony {
        box-shadow: 5px 5px 5px 5px rgba(235, 235, 235, 0.7);
        padding: 1rem;
        margin-bottom: 10rem;

        @media (max-width: 375px) {
           padding-right: 0;
           gap: 0;
        }

        img {
            object-fit: cover;
            height: 25rem;
            @media (max-width: 400px) {
                width: 22rem;
             }
        }
    }
}