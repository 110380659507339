@import '../../css/variabilities.scss';


.burger {
    @media (min-width: 751px) {
        display: none;
    }

    position: fixed;
    top: 2%;
    left: 2rem;
    width: 2rem;
    height: 2rem;
    background: transparent;
    cursor: pointer;
    z-index: 10;
    transition: transform 0.3s ease-in-out;

    .first-line {

        &.active {
            transform: rotate(45deg);
            position: fixed;
            top:3.7%;
        }
    }
    .second-line {

        &.active {
            transform: rotate(135deg);
            position: fixed;
            top:3.7%;
            
        }
    }
    .empty-line {

        &.active {
            display: none;
        }
    }



    .burger-btn {

        div {

            // width: 2rem;
            // height: 0.25rem;
            // background: #393939;
            // border-radius: 10px;
            // transition: all 0.3s linear;
            // position: relative;
            // transform-origin: 1px;

             border: 1.5px solid #393939;
             background-color: #393939;
             width: 3rem;
             margin-bottom: 0.8rem;
             padding: 0;
        }
        
    }
}
