@import '../../../../css/variabilities.scss';

.slidingGates {


    color: #393939;
    p {
        text-align: justify;
    }

    h2 {
        font-family: $roboto-slab;
        text-transform: uppercase;
        text-decoration: underline;
        size: 3rem;
        font-weight: bold;
        //margin-top: 10rem;

        // @media (max-width: 320px) {
        //     margin-top: 15rem;
        // }

    }


    h3 {
        text-transform: uppercase;
        size: 3rem;
        font-family: $roboto-slab;
        font-weight: bold;
    }

    img {
        border: 1px solid rgb(194, 194, 194);
    }

    .menu_content{
        display: flex;

        .button_content {
            flex-grow: 1;

            button {
                @media (max-width: 470px) {
                    font-size: 0.9rem;
                    padding: 0.5rem;
                }
                @media (max-width: 340px) {
                    font-size: 0.8rem;
                    padding: 0.5rem;
                }
            }
        }
    
    }

    img[alt='откатные ворота'], img[alt='откатные ворота Дорхан'], img[alt='цвета'] {
        width: 100%;
        margin-bottom: 1rem;
        object-fit: cover;
        margin-bottom: 5rem;
    }
    img[alt='цвета']{
        width: 70%;
    }
    

    .plusWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
        grid-template-rows: 1fr;
        gap: 1%;
        justify-items: center;
        align-items: center;
        margin-bottom: 7rem;

        @media (max-width: 375px) {
            margin-bottom: 15rem;
        }

        div > img {
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
        }
    };

    .complectWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center;
        gap: 1%;

        

        span {
            font-family: $roboto-slab;
            font-weight: bold;
        }
        p {
            margin: 1rem 0;
        }

        img {
            object-fit: cover;
            width: 90%;
            border: 0;

            @media (max-width: 550px) {
                margin-bottom: -5rem;
            }
            
        }
    }

    .variantWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
        grid-template-rows: 1fr;
        gap: 1%;
        justify-items: center;
        margin-bottom: 7rem;

        img {
            width: 23rem;
            margin-top: 0.5rem;
            border: 0;
        }
    }

    .controlWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
        grid-template-rows: 1fr;
        gap: 1%;
        justify-items: center;
        align-items: center;
        

        div > img {
            object-fit: cover;
            max-height: 100%;
            width: 15rem;
            
        }
    }

 .characteristics {
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-template-rows: 1fr 1fr 1fr;
     justify-items: center;
     

     p {
        padding: 1rem;
        border-bottom: 1.5px solid rgb(194, 187, 187);
     }
 }

 .design, .surface, .shield, .premium, .color {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-template-rows: 1fr;
    gap: 1rem;
    justify-items: center;
    margin-bottom: 7rem;

    @media (max-width: 500px) {
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
     }

    div {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 20rem;

        @media (max-width: 1100px) {
           width: 17rem;
        }
        @media (max-width: 800px) {
            width: 15rem;
         }
         @media (max-width: 600px) {
            width: 22rem;
         }
    }

    img {
        width: 100%;
        object-fit: fill;
    }
 }

 .design {
     img {
         width: 15rem;
     }
 }

 .shield {

    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 2rem;

    div: {
        width: 25rem;
    };

    img {
        height: 14rem;
        width: 25rem;
        object-fit: cover;
    }
 }

 

   
}