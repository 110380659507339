@import '../../css/variabilities.scss';

.sideMenu {
    list-style: none;
    text-align: left;
    max-width: 20%;
    margin-top: 4rem;
    padding-right: 1rem;
    font-family: $roboto-slab;

    @media (max-width: 750px) {
        display: none;
    }

    ul {
        margin-bottom: 1rem;
        list-style: none;
        padding-left: 0;

        a{
            text-decoration: none;
            transition: 200ms;
            font-family: $roboto-slab;


            &:visited {
                color: #393939;
            };

            &:hover, &:active {
                color: #43a906;
                font-weight: 700;
                //letter-spacing: 1px;
                text-decoration: underline;
            };

            
          };
       
    }

      .fa-chevron-down {
         padding-left: 0;
         color:#43a906;
         cursor: initial;
      };

      .fa-grip-lines-vertical {
          padding-left: 0;
          cursor: initial;
      }


     i {
         margin-right: 0.75rem;
         padding-left: 1rem;
     }

    .menu_item {
        color: #393939;
        cursor: pointer;
        margin-top: 1rem;

        @media (max-width: 800px) {
            margin-top: 1.3rem; 
        };
    }

     .submenu_item {
        
        border-left: 3px solid #DDDDDD;
        margin-top: 1rem;
        margin-left: 1.5rem;
        padding-left: 1rem;
        text-transform: initial;
        transition-property: all;
        transition: 200ms;

        @media (max-width: 800px) {
            margin-top: 1.3rem; 
        };
        

        &:hover, &:active, &:focus {
            color: #43a906;
            text-decoration: underline;
            //letter-spacing: 1px;
        }
        // &.gates.active {
        //     display: none;
        //     }

       
    }
    .submenu_item_gate, .submenu_item_rollet, .submenu_item_alCon, .submenu_item_plCon, .submenu_item_fence, .submenu_item_fire {
        &.active {
            display: none;
            } 
    }

    & > ul:last-of-type > li {
        text-transform: uppercase;
    }
}