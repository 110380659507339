@import '../../../css/variabilities.scss';


.picket {
    
    img[alt='заборы из евроштакетника'] {
        width: 100%;
        height: 20rem;
        object-fit: cover;
        margin-bottom: 7rem;
    }

    .plusesPicket{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        gap: 3rem;
        justify-items: center;
        align-items: center;
        box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.4);
        padding: 1rem;
        margin-bottom: 7rem;

        img {
            object-fit: cover;
            width: 100%;
        }
        li {
            text-align: left;
            padding-bottom: 1rem;
        }
    }

    .picketKinds, .picketColor {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        gap: 3rem;
        justify-items: center;
        align-items: center;
        

        img {
            object-fit: cover;
            width: 20rem;
        }
        p{
            text-align: center;
            text-transform: uppercase;
        }
    }
    .picketView{
        display: flex;
        flex-direction: column;
    }
    .picketColor{
        margin-bottom: 7rem;
        img {
            object-fit: cover;
            width: 12rem;
            height: 12rem;
        }
    }
}