@import './css/variabilities.scss';

.header-info {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; 
    color: #646464;

    @media (max-width: 774px) {
        flex-direction: column;  
        align-items:flex-end;
        font-size: 10px; 
    };

    .fa-grip-lines-vertical {
        color: #43a906;
        margin-right: 1rem;
        @media (max-width: 774px) {
            display: none;
        };
    }

    a {
        font-size: 1.2rem;
        margin-right: 1rem;
        padding-top: 0.5rem; 
        color: #646464;
        font-family: $roboto-slab;
        @media (max-width: 360px) {
            font-size: 1rem;
            margin-right: 0.2rem;
        };
    };

    i{
        color: rgb(213, 53, 85);
        font-size: 1.2rem;

        @media (max-width: 360px) {
            font-size: 1rem;
        };
    };
   
    span {
        @media (max-width: 774px) {
            margin-right: 1rem;
        };
        @media (max-width: 380px) {
            margin-right: 0.2rem;
        };
    }
    p{
        font-family: $roboto-slab;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .address {
        @media (max-width: 380px) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }; 
    }

    .delivery{

        i {
            margin-right: 0.3rem;
        }
       span {
            margin-right: 0.3rem;
            margin-top: 0.5rem;
        }
        @media (max-width: 774px) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }; 
    }
}






