@import '../../../css/variabilities.scss';


.aluWall {


    p {
        text-align: left;
        margin-top: 1rem;

        @media (max-width: 375px) {
            text-align: justify;
        }
    }

    .menu_content{
        display: flex;
    
        .button_content {
            padding-top: 5rem;

            @media (max-width: 375px) {
                padding-top: 2rem;
            }
        }
    }

    img[alt='алюминиевые перегородки'] {
        width: 100%;
        height: 20rem;
        object-fit: cover;
        border: 1px solid rgb(160, 160, 160);
    }

    .coldWarmAl, .wallTask, .aluWallExample {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center;
        grid-gap: 2%;
        margin: 3rem 0 8rem 0;

        img {
            object-fit: cover;
            width: 100%;
            height: 15rem;
        }

        .kind {
            padding: 1rem;
            @media (min-width: 768px) and (max-width: 820px) {
                width: 25rem;
             }
            }     
        
        

        p, li {
            text-align: justify;
        }
        
    }
    .wallTask {
        box-shadow: 5px 5px 5px 5px rgba(235, 235, 235, 0.7);
        padding: 1rem;

        @media (max-width: 375px) {
            padding: 0.5rem;
        }
    }
    .aluWallExample {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));

         @media (min-width: 561px) and (max-width: 943px) {
             grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
          }
          @media (max-width: 490px) {
            grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
         }
    }

    .wallTask, .aluWallExample {
        img {
            object-fit: cover;
            width: 100%;
            height: 15rem;
            border: 1px solid grey;

            @media (max-width: 490px) {
                height: 22rem;
             }
        }
    }

    .carousel{
        max-width: 600px;
        margin: 0 auto;

        @media (max-width: 650px) {
            max-width: 500px;  
        };
        @media (max-width: 530px) {
            max-width: 400px;  
        };
        @media (max-width: 430px) {
            max-width: 300px;  
        };

        .carousel_item {
            height: 30rem;
            max-width: 94%;
            object-fit: cover;
            border: 1px solid rgb(184, 184, 184);
            box-shadow: 5px 5px 5px rgba(72, 72, 72, 0.4);
            @media (max-width: 530px) {
                height: 25rem; 
                object-fit: cover;
            };
            @media (max-width: 430px) {
                height: 20rem; 
            };  
        }

        .alice-carousel__prev-btn{
            text-align: center;
        }
    }
}