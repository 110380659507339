@import '../../css/variabilities.scss';

.contacts {

    padding: 7rem 0;

    @media (max-width: 1024px) {
        padding: 2rem 0;  
    }
    
    
    h2 {
            font-family: $roboto-slab;
            font-size: 3rem;
            text-transform: uppercase;
            font-weight: bold;
            padding-bottom: 0.5rem;

            @media (max-width: 1024px) {
                font-size: 2rem;
                margin-top: -1rem;
            }
    }

    .map-message {
        display: flex;
        align-items: stretch;
        box-sizing: border-box;
        margin-top: 3rem;
        text-align: left;

        @media (max-width: 1024px) {
            margin-top: 1.5rem; 
        }

        @media (max-width: 810px) {
            flex-wrap: wrap;  
            text-align: center;
        }

       

        h4 {
            font-family: $roboto-slab;
            font-size: 1.5rem;
            text-transform: uppercase;
            font-weight: bold;
        }
        }

        .map {
            width: 60%;
            margin-right: 3rem;

            @media (max-width: 810px) {
                width: 100%; 
                margin-right: 0;
            }


            a {
                display: inline-block;
                font-family: $open-sans;
                padding-top: 0;
                padding-bottom: 2rem;
                

            }
            i {
                color: rgb(213, 53, 85);
                padding-top: 0;
                padding-bottom: 2rem;
                margin-right: 0.5rem;
            }

            span + i {
                margin-left: 2rem;
            }

        }

        .drop-message {
            width: 100%;
            padding-bottom: 0;

            button {
                padding: 1.5rem 5.2rem;
                margin-top: 2.5rem;
                border-radius: 2px;
                background-color: #43a906;
                font-family: $roboto-slab;
                font-weight: bold;
                text-transform: uppercase;
                color: #ffffff;
                cursor: pointer;
                
            }
        
        }

        .message-form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: 4rem 4rem 20rem;
            column-gap: 1rem;
            row-gap: 1rem;
            

            [placeholder="Телефон *"], [placeholder="Сообщение *"] {
                grid-column: span 2;
            }
            [placeholder] {
                font-family: $open-sans;
                font-size: 1.2rem;
                font-style: italic;
                text-align: left;
                color: #696969;
                padding-left: 1rem;

                @media (max-width: 1024px) {
                    padding: 0;
                }

            }
        }
    }
