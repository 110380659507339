@import '../../css/variabilities.scss';

.plasticConstructions {
    box-sizing: border-box;
    color: red;


    .menu-products {
        display: flex;
        align-items: start;
        text-align: center;
    };

    .products {
        flex-basis: 60%;
        margin: 4rem auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        grid-gap: 3%;
        

        @media (max-width: 841px) and (min-width: 751px) {
            grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr)); 
        };


        .product {
            border: 1px solid rgb(184, 184, 184);
            box-shadow: 3px 2px 7px 0px rgba(72, 72, 72, 0.2);
            background-color: #fff;

            &:hover {

                p {
                    color: #43a906;
                }
              };
        }

        p {
            font-family: $roboto-slab;
            text-transform: uppercase;
            padding: 1rem;

        }
       

        img {
            object-fit: cover;
            width: 100%;
            height: 14rem;
        }
    }
}