@import '../../css/variabilities.scss';

.products {
    margin-top: 0.5em;

    .gallery-container {
        width: 1170px;
        height: 400px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        .slide {
            display: block;
            height: 100%;

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
        .active {
            
            height: 100%;
        }

        

        .dots {
            justify-self: flex-end;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);

            button {
                width: 15px;
                height: 15px;
                border-radius: 20px;
                border: 2px solid white;
                background-color: rgba(155, 155, 155, 0.2);
                padding: 0;
                margin: 0;
                outline: 0;

                &.activeDot{
                    background-color:  white;
                }
    
            }
           
        }

        .arrow-prev {
            position: absolute;
            width: 50px;
            height: 100%;
            top: 0;
            cursor: pointer;
               
          &:after {
            position: absolute;
            top:50%;
            left: 25px;
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border-left: 5px solid white;
            border-bottom: 5px solid white;
            transform: rotate(45deg);
          }

          &:hover {
            background-color: rgba(155, 155, 155, 0.6);
          }
        }

        .arrow-next {
            position: absolute;
            width: 50px;
            height: 100%;
            top: 0;
            left: 1120px;
            cursor: pointer;

            &:after {
                position: absolute;
                top:50%;
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                border-right: 5px solid white;
                border-bottom: 5px solid white;
                transform: rotate(-45deg);
              }
             
            &:hover {
                left: 1120px;
                background-color: rgba(155, 155, 155, 0.6);
              }  
        }
        
    }
    
}