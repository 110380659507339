@import '../../css/variabilities.scss';

.findPrice {
    .contentCallback {
        padding-top: 2rem;
        padding-bottom: 2rem;
        text-align: center;
        width: 60%;
        margin: 0 auto;

        @media (max-width: 1024px) {
            padding-top: 0.7rem;
            padding-bottom: 0.7rem; 
        }
    
    
    h2 {
            font-family: $roboto-slab;
            font-size: 2rem;
            color: #222222;
            text-transform: uppercase;
            font-weight: bold;
            padding-bottom: 0.5rem;

            @media (max-width: 1024px) {
                font-size: 1.5rem;
            }
    };
    button {
        padding: 1.5rem 5.2rem;
        margin-top: 2.5rem;
        border-radius: 2px;
        background-color: #43a906;
        font-family: $roboto-slab;
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
        cursor: pointer;


        @media (max-width: 375px) {
            padding: 1.5rem 3rem;
        }
        
    }
    [placeholder] {
        font-family: $open-sans;
        font-size: 1rem;
        font-style: italic;
        text-align: left;
        color: #696969;
        padding-left: 0.5rem;
        min-width: 18rem;

    }

    .callbackWrapper {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0 auto;
        align-items: center;

        input, textarea {
            margin-bottom: 1rem;
            height: 2.5rem;
        };

        [placeholder="Вид продукции для просчета, размеры, цвет, дополнительные аксессуары и другие важные на Ваш взгляд параметры *"] {
            height: 10rem;
        }
    }
    }
}