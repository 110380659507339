@import '../../../../css/variabilities.scss';

.fastGates {

    img[alt='спиральные ворота'] {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .PVHGates {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        gap: 1rem;
        box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.4);
        padding: 1rem;

        h3 {
            font-family: $roboto-slab;
        }

        img {
            object-fit: cover;
            width: 100%;
        }
        .characteristics {
            margin-bottom: 1rem;
        }
    }
    .fastDesign {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 1rem;

        @media (max-width: 650px) {
            flex-wrap: wrap;  
        }

        img {
            object-fit: cover;
            height: 6rem;
            width: 15rem;
        }
    }

    .spiralGates {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center;
        box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.4);
        margin-top: 3rem;
        margin-bottom: 7rem;
        height: 30rem;

        @media (max-width: 500px) {
            grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        }

        img {
            object-fit: cover;
            height: 30rem;
        }
        p {
            padding: 1rem;
        }
    }


    .characteristics {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        justify-items: center;
        margin-bottom: 7rem;
        
   
        p {
           padding: 1rem;
           border-bottom: 1.5px solid rgb(194, 187, 187);
        }
    }
    .design {
        width: 25rem;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .color, .fastColor {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-template-rows: 1fr;
        gap: 1rem;
        justify-items: center;
        margin-bottom: 7rem;
       
           @media (max-width: 500px) {
                grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
            }
    
        div {
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            width: 10rem;
    
        }
    
        img {
            object-fit: fill;
            width: 100%;
            
        }
     }

     .fastColor {
         img {
            height: 8rem;
            object-fit: cover;
         }
     }
   
}