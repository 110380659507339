
$roboto-slab: 'Roboto Slab', ;
$open-sans: 'Open Sans', ;

* {
    box-sizing: border-box;
  }

html, body, p, a, img {
    margin: 0;
    padding: 0;
    font-family: $open-sans, sans-serif;
    font-size: 14px;
    color: #393939;

    @media (max-width: 1024px) {
        font-size: 12px;  
    }
    @media (max-width: 768px) {
        font-size: 11.7px;    
    };

};

.container {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.7rem;

    @media (max-width: 1024px) {
        max-width: 1024px;  
    };
    @media (max-width: 992px) {
        max-width: 970px;  
    };
    @media (max-width: 768px) {
        max-width: 750px;  
    };
    @media (max-width: 375px) {
        max-width: 375px;  
    };
     @media (max-width: 320px) {
         max-width: 320px;  
     };
};

a {
    display: inline-block;
    text-decoration: none;
}

h3 {
    text-transform: uppercase;
    font-family: $roboto-slab;
    font-weight: bold;
}

h4 {
    text-transform: initial;
    font-family: $roboto-slab;
    font-size: 1.05rem;
}

h5 {
    text-transform: uppercase;
    font-family: $roboto-slab;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: -2.5rem;
    margin-top: 5rem;

    @media (max-width: 1024px) {
        margin-bottom: -1rem;   
    };
}
span {
    font-family: $roboto-slab;
    font-weight: bold;
}

.menu_content{
    display: flex;

    .button_content {
        flex-grow: 1;
    }

}

.buttonWrapper {
    display: flex;
    justify-content: space-around;
    margin: 5rem 1rem 1.5rem 1rem;

    @media (max-width: 1024px) {
        margin-top: 2rem;  
    }

    button {
        padding: 1.6rem;
        margin-right: 1rem;
        font-family: $roboto-slab;
        text-transform: uppercase;
        border: 1.5px solid  rgb(67, 169, 6);
        border-radius: 5px;
        border-bottom: 1.5px solid #fbfbfb;
        border-left: 1.5px solid #fbfbfb;
        background-color: rgba(67, 169, 6, .7);
        box-shadow: 3px 3px 3px -3px rgba(155, 155, 155, 0.2);
        color:white; 

        @media (max-width: 375px) {
            padding: 1rem; 
        }


        &:hover, &:active, &:focus {
        outline: none;
        font-weight: bold;
        border: 2.5px solid  rgb(213, 53, 85);
        background-color: rgb(213, 53, 85);
        text-decoration: underline;
        color: white;
        };
        
    };
};
.content {
    border: 2px solid #fbfbfb;
    background-color: #fbfbfb;
    box-shadow: 3px 3px 3px -3px rgba(155, 155, 155, 0.2);
    margin-bottom: 5rem;
    font-family: $open-sans;
    padding: 3rem 2rem;

    @media (max-width: 375px) {
        padding: 0.5rem;
    }
}
.additionWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 3rem;
    margin-bottom: 10rem;
    @media (max-width: 400px) {
        grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
    }

    img {
        width: 100%;
    }

    div {
        border: 1px solid rgb(184, 184, 184);
        box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.2);
        background-color: #fff;
    }

    p {
        padding: 1rem;
    }
};
.baseWrapper {
    box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.4);
    padding: 0.3rem;
    margin-top: 3rem;
    margin-bottom: 7rem;
 }

 .base  {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    margin: 1rem;
    @media (max-width: 400px) {
        grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
    }


    img {
        object-fit: cover;
        width: 20rem;
    }
}
.characteristics {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
    margin-bottom: 7rem;
    

    p {
       padding: 0.5rem;
       border-bottom: 1.5px solid rgb(194, 187, 187);
    }
}
.automaticSection, .doorSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1rem;

    margin: 7rem 0;
    padding: 1rem;
    text-align: center;
    border: 1px solid rgb(184, 184, 184);
    box-shadow: 3px 2px 7px 0px rgba(72, 72, 72, 0.2);
    background-color: #fff;
    
    &:hover {
        p, h4 {
            color: #43a906;
        }
    };
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h4 {
        font-size: 1.5rem;
    }
    p{
        text-align: center;
    }
    img {
        height: 20rem;
        width: 100%;
        object-fit: cover;
        @media (max-width: 375px) {
            height: 10rem;
        }         
    }
    .fa-hand-point-right {
        font-size: 3rem;
        color: #43a906;

        @media (max-width: 819px) and (min-width:751px) {
            transform: rotateZ(90deg);
        } 
        @media (max-width: 642px) {
            transform: rotateZ(90deg);
        }  
    }
}


