@import '../../css/variabilities.scss';

.order {

    margin-top: 4rem;

    @media (max-width: 1024px) {
        margin-top: 2rem;
       }



    h2 {
        font: 3rem $roboto-slab;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media (max-width: 1024px) {

         font: 2rem $roboto-slab;
         font-weight: bold;
        }
    }

    .orderWrapper {
        display: grid;
            grid-template-columns: 15% 70% 15%;
            grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
            align-items: center;
    
            i {
                color: rgb(213, 53, 85);
                font-size: 5rem;
                grid-row: span 2;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }

            };

            .fa-reply, .fa-share {
                color: rgba(155, 155, 155, 0.9);
                transform: rotateX(150deg);
            }
    
    
    
            p {
                margin-bottom: 3.5rem;
                margin-top: 1.5rem;
                font-family: $open-sans;
                color: #222222;
                text-transform: uppercase;

                @media (max-width: 1024px) {
                    margin-bottom: 2.5rem;
                    margin-top: 1rem;
                }
            }

            a {
                color:#43a906;
                font-size: 1.2rem;
            }
    }
}

