@import '../../../../css/variabilities.scss';

.garageGates {

    text-align: justify;

    img {
        border: 1px solid rgb(193, 193, 193);
    }
    .menu_content{
        display: flex;

        .button_content {
            flex-grow: 1;

            button {
                @media (max-width: 360px) {
                    font-size: 1rem;
                }
            }
            

        }
    
    }

    .content {

        h3 {
            text-align: center;
        }
    
        h4 {
            text-align: center;
            margin-top: 5rem;
        }
        h5 {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        p {
            text-align: justify;
            margin: 1rem 0;
        }

        span {
            font-family: $roboto-slab;
            font-style: italic;
            font-weight: bold;
        }

        h5 {
            font-family: $roboto-slab;
            font-weight: normal;
            font-style: italic;
            font-size: 1rem;
            text-transform: uppercase;
            margin-top: 5rem;
            text-align: left;
        }

        .characteristicWrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
            grid-template-rows: 1fr;
            grid-gap: 1%;



            @media (max-width: 320px) {
                grid-gap: 0;
            }


            div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }


            h4 {
                margin-top: 0;
            }

            div {
                padding: 2rem 1rem;
                color: #393939;
                line-height: 1.25rem;
                text-align: justify;
              

                li {
                    margin-top: 1rem;
                }
            }

            p {
                
                padding-bottom: 0.5rem;
            }

            img {
                width: 15rem;
                margin-top: 2rem;
            }
        };

        .panelWrapper,.colors, .woodColor {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
            grid-template-rows: 1fr;
            grid-gap: 1%;
            justify-items: center;
            align-items: center;
            

        }

        .panelWrapper {
            grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
            grid-gap: 2%;
            
            @media (max-width: 992px) {
                grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr)); 
            }
            @media (max-width: 375px) {
                grid-gap: 0%; 
            }

            img {
                width: 11rem;
            }  
        }

        .colors {
            img {
                width: 9.5rem;
            }
            @media (max-width: 992px) and (min-width: 768px) {
                grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr)); 
            }
        }

        .windowsWrapper, .doorWrapper, .woodColor{

            display: grid;
                 grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
                 grid-template-rows: 1fr;
                 justify-items: center;

             @media (max-width: 950px) and (min-width: 551px) {
                 grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
                 grid-gap: 1rem;
             }
             @media (max-width: 550px) {
                grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
                grid-gap: 1rem;
            }
               
             @media (max-width: 375px) {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 1fr 1fr 1fr;    
             }
            
            img {
              
                height: 10rem;
                @media (max-width: 950px) and (min-width: 768px) {
                        height: 12rem;
                        
                     }
                @media (max-width: 375px) {
                   
                    height: 12rem;
                    margin-bottom: 1rem;
            
                 }
            }
        }

        .woodColor {

            margin-bottom: 1rem;
            @media (max-width: 950px) {
                grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
                grid-gap: 1rem;
            }
            @media (max-width: 500px) {
                grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
                grid-gap: 1rem;
            }
            @media (max-width: 375px) {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 1fr 1fr;    
             }
            img {
                height: 11rem;

                @media (max-width: 950px) and (min-width: 651px) {
                    height: 15rem;
                }
                @media (max-width: 500px) {
                    height: 16rem;
                }
            }
        }

        .panelColor {

            img {
                @media (max-width: 1300px) {
                    max-height: 75%;
                }
                @media (max-width: 900px) {
                    max-height: 70%;
                }
                @media (max-width: 800px) {
                    max-height: 65%;
                }
                @media (max-width: 750px) {
                    max-height: 58%;
                }
                @media (max-width: 670px) {
                    max-height: 52%;
                }
                @media (max-width: 600px) {
                    max-height: 46%;
                }
                @media (max-width: 520px) {
                    max-height: 40%;
                }
                @media (max-width: 450px) {
                    max-height: 35%;
                }
                
                @media (max-width: 390px) {
                    max-height: 30%;
                }
                @media (max-width: 340px) {
                    max-height: 28%;
                }
            }

            .ri-trigger {
                width: 436px;
                @media (max-width: 436px) {
                    width: 320px;
                }
                @media (max-width: 400px) {
                    width: 280px;
                }
            }
        }

        .wicketWrapper {
            margin-top: 2rem;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
            grid-template-rows: 1fr;
            grid-gap: 1%;
            justify-items: center;

            @media (max-width: 992px) {
                grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
            }

            
            @media (max-width: 398px) {
                grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
            }


            div {
                margin-bottom: 2rem;
            };
            img {
                width: 31rem;
                @media (max-width: 992px) {
                    width: 29rem;
                }
                @media (max-width: 375px) {
                    width: 25rem;
                }
            }
        };


            .techWrapper{
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
                grid-template-rows: 1fr;
                grid-gap: 1%;
                justify-items: center;
                align-items: center;


                @media (max-width: 935px) and (min-width: 790px) {
                    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));   
                 }
                 @media (max-width: 789px) {
                    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));  
                    text-align: center;
                 }
                
            }
         
           .lockWrapper, .unblockingWrapper, .RSD01, .RSD02, .surface, .design, .color, .base {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            grid-template-rows: 1fr;
            justify-items: center;
            align-items: center;
            grid-gap: 1%;
            
                p{
            
                  text-align: justify;
                  padding-right: 3rem;
                  padding-left: 3rem;
                }
           }


        .techCharacteristics, .RSD01, .RSD02 {

            @media (max-width: 580px) {
                grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
                text-align: center;
            }

            div {
                width: 100%;
            }

            img {
                width: 28rem;
                margin: 4rem 2rem;

                @media (max-width: 870px) {
                    object-fit: cover;
                    width: 24rem;
                    margin: 0;
                    }

                @media (max-width: 375px) {
                    object-fit: cover;
                    width: 100%;
                    margin: 0;
                    }
            }
            
            li {
                padding: 1rem;
            }
        }

        

        .surface, .design, .color, .base {

            @media (min-width: 760px) and (max-width: 992px) {
                grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
             }
             @media (max-width: 759px) and (min-width: 400px) {
                grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
                grid-gap: 0;
             }
             @media (max-width: 375px) {
                grid-gap: 0;
             }

            div {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
            
            img {
                width: 15rem;
                margin: 1.5rem;

                @media (min-width: 768px) and (max-width: 991px) {
                    width: 12rem;
                    margin: 1rem;
                 }
                 @media (max-width: 759px) {
                    width: 10rem;
                    margin: 1rem;
                 }
                 @media (max-width: 375px) {
                    width: 17rem;
                    margin: 1rem;
                 }
            }
        }

        .color {

            @media (max-width: 320px) {
                grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
             }

             img {
                @media (max-width: 375px) {
                    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
                 }
             }

        }

        .design {
            @media (max-width: 770px) {
                grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
             }
        }

       
        .baseWrapper {
            box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.4);
            padding: 0.3rem;
            margin-top: 3rem;
        }
        

        .techTable {
            width: 100%;
            display: grid;
            grid-template-rows: repeat (16, 1fr);
            grid-template-columns: 1fr 1fr;

  
              p {
                border: 1px solid #dddddd;
                padding: 0.35rem;
                text-align: center;
                

                &:nth-child(3n+1) {
                    grid-column: span 2;
                }
              }
              
              
        }

    };

    .additionWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        grid-gap: 3%;
        margin-bottom: 10rem;

        @media (max-width: 549px) {
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            grid-gap: 1rem;
         }

        @media (max-width: 375px) {
            grid-gap: 1rem;
         }

        img {
            width: 100%;
        }
       

        div {
            border: 1px solid rgb(184, 184, 184);
            box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.2);
            background-color: #fff;
        }

        p {
            padding: 1rem;
        }
        .windows {

            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            img {
                width: 9rem;
                margin-left: 0.7rem;
            }
        } 
    }


img[alt='Гаражные ворота Hörmann'] {
     height: 20rem;
     width: 100%;

    @media (max-width: 600px) {
        object-fit: cover;
        height: 15rem;
    }
    @media (max-width: 375px) {
        height: 17rem;
    }
}

 
.hoermannFeatures, .woodgrain, .decocolor, .promatic, .prolift, .supra, .remote  {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    grid-gap: 1%;

    img {
        object-fit: cover;
        width: 21rem;
        height: 20rem;
    }

}
.woodgrain, .decocolor {
    img {
        @media (max-width: 992px) {
            width: 20rem;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            width: 16rem;
            height: 16rem;
         }
         @media (min-width: 500px) and (max-width: 767px) {
            width: 15rem;
            height: 15rem;
         }
    }
    
    @media (max-width: 992px) {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }

    @media (max-width: 490px) {
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    }
    

}
.hoermannFeatures {
    margin: 7rem 0;
    box-shadow: 5px 5px 5px 5px rgb(235, 235, 235);
    padding: 1rem;
}
.sizes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8.5rem, 1fr));
    grid-template-rows: 1fr;
    margin-bottom: 5rem;
   }


}