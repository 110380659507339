@import '../../css/variabilities.scss';

.burger-menu {

    @media (min-width: 751px) {
        display: none;
    }

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    z-index: 10;
    position: fixed;
    top: 8%;
    left: 0;
    background-color: #373a47;
    color: white;
    width: 70%;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;

    &.active {
        transform: translateX(0);
        }


    .burger-link, .sub-link {
        color: white;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        font-family: $roboto-slab;
        padding: 0.3rem;
    }

    .burger-link {
        padding: 1rem;
        font-size: 1.1rem;
        letter-spacing: 0.2rem;
    }

    .sub-link {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        
        font-size: 0.8rem;
    }
    .sub-link + .sub-link {
        padding-top: 1.2rem;
    }

    
    }


