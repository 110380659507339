@import '../../../css/variabilities.scss';


.fence3d {


    p {
        text-align: left;
        margin-top: 1rem;

        @media (max-width: 375px) {
            text-align: justify;
        }
    }

    .menu_content{
        display: flex;
    
    }

    .fenceTittle {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        grid-gap: 1%;
        @media (min-width: 560px) and (max-width: 970px) {
            grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
            justify-items: center;
         }
        @media (min-width: 350px) and (max-width: 559px) {
            grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
            justify-items: center;
         }

         @media (max-width: 375px) {
            justify-items: center;
        }

        

        img {
            width: 21rem;
            height: 18rem;
            object-fit: cover;

            @media (min-width: 880px) and (max-width: 970px) {
                width: 19rem;
                height: 17rem;
             }
             @media (min-width: 751px) and (max-width: 879px) {
                width: 16rem;
                height: 15rem;
             }

             @media (min-width: 560px) and (max-width: 749px) {
                width: 15.5rem;
                height: 13rem;
             }
             @media (max-width: 559px) {
                width: 25rem;
                height: 13rem;
             }
        }
    }

    .featureFence {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
        grid-template-rows: 1fr;
        justify-items: center;

        img {
            width: 21rem;
            object-fit: cover;
            border: 1px solid grey;
        }
        li {
            margin: 1rem 0;
            text-align: left;
        }
    }

    .fenceGates {
        
        background-color: #fff;
        border: 1px solid rgb(182, 182, 182);
        margin-top: 5rem;
        box-shadow: 0px 1px 3px 0 rgba(72, 72, 72, 0.4);
        

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem;
            @media (max-width: 760px) {
            flex-wrap: wrap;
            }
           
          
            
        }

        img {
            width: 30rem;
            object-fit: cover;
            border: 1px solid rgb(182, 182, 182);
            @media (max-width: 390px) {
                width: 24rem;
                }
        }
        p{
            margin: 1rem;
        }
    }

}